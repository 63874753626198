/* @Hooks */
import { useIntl } from 'react-intl';

/* @Components */
import OptimizedImage from '@/components/optimized-image';

function Presentation(): JSX.Element {
  const { formatMessage } = useIntl();

  const presentations = [
    formatMessage({ defaultMessage: 'Malware detection' }),
    formatMessage({ defaultMessage: 'Hard disk capacity check' }),
    formatMessage({ defaultMessage: 'Memory usage check and release' }),
    formatMessage({ defaultMessage: 'CPU utilization rate check' }),
    formatMessage({ defaultMessage: 'Network speed' }),
    formatMessage({ defaultMessage: 'Connection with Dropbox' }),
    formatMessage({ defaultMessage: 'Japanese support' }),
  ];

  return (
    <section className="mb-24 sm:mb-40">
      <div className="max-w-screen-lg mx-auto px-4">
        <h2 className="h2 text-center">
          {formatMessage({ defaultMessage: 'You can check the status of your Mac with just one click' })}
        </h2>
        <div className="flex flex-col lg:flex-row justify-center space-y-8 lg:space-x-10 lg:space-y-0">
          <div className="px-0 py-12 w-full mx-auto lg:w-1/2 lg:px-10 sm:px-20 lg:py-14 bg-gradient-to-br from-[#5559A3] via-[#5F478D] to-[#644174]">
            <div className="block mx-auto max-w-md md:w-[442px] min-h-[727px] relative">
              <OptimizedImage src="/images/cmm-bar@2x.png" alt="" fill={true} />
            </div>
          </div>
          <div className="px-5 py-16 w-full lg:w-1/2 sm:px-20 lg:py-36 bg-gradient-to-bbr from-[#FFF6FD] to-[#E2D7F9]">
            <h3 className="max-w-sm font-bold mb-9 md:mb-8 mx-auto text-xl leading-relaxed">
              {formatMessage({
                defaultMessage:
                  'Click the CleanMyMac X icon in the menu bar at the top of your Mac screen to view real-time system data.',
              })}
            </h3>
            <ul className="max-w-sm mx-auto leading-[45px] pl-3">
              {presentations.map((presentation) => {
                return (
                  <li
                    key={presentation}
                    className="relative before:absolute before:top-[50%] before:-left-3 before:w-[2px] before:h-[2px] before:rounded-full before:bg-macpaw-black"
                  >
                    {presentation}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Presentation;
