/* @Hooks */
import { useIntl } from 'react-intl';
import OptimizedImage from '@/components/optimized-image';

function Benefit(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <section className="mb-24 sm:mb-0 py-24 text-white bg-gradient-to-bbr from-[#251f56] via-[#1e113e] to-[#3b123c]">
      <div className="mx-auto max-w-screen-lg px-4">
        <h2 className="h2 text-center">{formatMessage({ defaultMessage: 'Yes! CleanMyMac X can solve it all.' })}</h2>
        <ul className="flex flex-col items-center lg:flex-row lg:items-start lg:space-x-10 space-y-16 lg:space-y-0">
          <li className="max-w-md lg:w-1/3 flex-grow flex flex-col items-center">
            <h3 className="h3 lg:mb-5 text-center h3-bullet before:bg-macpaw-green">
              {formatMessage({ defaultMessage: 'cleaning' })}
            </h3>
            <figure className="mb-4 lg:mb-8 w-full">
              <div className="mb-4 lg:mb-5 rounded-md block overflow-hidden max-w-[10rem] mx-auto">
                <OptimizedImage src="/images/cleaning@2x.png" alt="" width="165" height="165" />
              </div>
              <figcaption className="font-bold max-w-[14rem] mx-auto text-xl text-center leading-normal">
                {formatMessage({ defaultMessage: 'Delete gigabytes of junk data in minutes' })}
              </figcaption>
            </figure>
            <p>
              {formatMessage({
                defaultMessage:
                  'CleanMyMac X chases unwanted data to every corner of your macOS. Get rid of obsolete caches, corrupted download files, logs, useless localizations and other unwanted files. You can find and delete file debris, emails, photos, and even large hidden files hidden in iTunes.',
              })}
            </p>
          </li>
          <li className="max-w-md lg:w-1/3 flex-grow flex flex-col items-center">
            <h3 className="h3 lg:mb-5 text-center h3-bullet before:bg-macpaw-green">
              {formatMessage({ defaultMessage: 'Security measures' })}
            </h3>
            <figure className="mb-4 lg:mb-8 w-full">
              <div className="mb-4 lg:mb-5 rounded-md block overflow-hidden max-w-[10rem] mx-auto">
                <OptimizedImage src="/images/security@2x.png" alt="" width="165" height="165" />
              </div>
              <figcaption className="font-bold max-w-[14rem] mx-auto text-xl text-center leading-normal">
                {formatMessage({ defaultMessage: 'Protect your machine from all risks' })}
              </figcaption>
            </figure>
            <p>
              {formatMessage({
                defaultMessage: `Is there a virus on my Mac? If you're cleaning your Mac with CleanMyMac X, your Mac is virus-free. It repels all threats to macOS such as malware, adware and ransomware, and if a problem is found, it will be removed immediately.`,
              })}
            </p>
          </li>
          <li className="max-w-md lg:w-1/3 flex-grow flex flex-col items-center">
            <h3 className="h3 lg:mb-5 text-center h3-bullet before:bg-macpaw-green">
              {formatMessage({ defaultMessage: 'Speeding up' })}
            </h3>
            <figure className="mb-4 lg:mb-8 w-full">
              <div className="mb-4 lg:mb-5 rounded-md block overflow-hidden max-w-[10rem] mx-auto">
                <OptimizedImage src="/images/speedup@2x.png" alt="" width="165" height="165" />
              </div>
              <figcaption className="font-bold max-w-[14rem] mx-auto text-xl text-center leading-normal">
                {formatMessage({ defaultMessage: 'Optimize and speed up your system' })}
              </figcaption>
            </figure>
            <p>
              {formatMessage({
                defaultMessage:
                  'Every time your Mac freezes, you can use various acceleration tools such as RAM release, maintenance script execution, login item management, startup agent, and freeze app. This offloads your system and optimizes your Mac for maximum productivity.',
              })}
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Benefit;
