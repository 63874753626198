/* @Hooks */
import { useIntl } from 'react-intl';

/* @Icons */
import Venturebeat from '@/icons/venturebeat.svg';
import Macstories from '@/icons/macstories.svg';
import Imore from '@/icons/imore.svg';
import Cultofmac from '@/icons/cultofmac.svg';
import Techcrunch from '@/icons/techcrunch.svg';
import Livedoor from '@/icons/livedoor.svg';
import Cnet from '@/icons/c-net.svg';
import Newsweek from '@/icons/newsweek.svg';
import Rakuten from '@/icons/rakuten.svg';
import Smth from '@/icons/smth-jpn.svg';

const mentions = [
  'venturebeat',
  'macstories',
  'imore',
  'cultofmac',
  'techcrunch',
  'livedoor',
  'cnet',
  'newsweek',
  'rakuten',
  'smth',
] as const;

const mentionIconByName = {
  venturebeat: <Venturebeat />,
  macstories: <Macstories />,
  imore: <Imore />,
  cultofmac: <Cultofmac />,
  techcrunch: <Techcrunch />,
  livedoor: <Livedoor />,
  cnet: <Cnet />,
  newsweek: <Newsweek />,
  rakuten: <Rakuten />,
  smth: <Smth />,
};

function Mentions(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <section className="mb-24 sm:mb-40">
      <div className="max-w-screen-lg px-4 mx-auto">
        <h2 className="h2 text-center">{formatMessage({ defaultMessage: 'Introduced in major media' })}</h2>
        <div className="flex flex-wrap justify-center">
          {mentions.map((mention) => {
            return (
              <div className="w-1/2 sm:w-[197px] px-4" key={mention}>
                {mentionIconByName[mention]}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Mentions;
