/* @Hooks */
import { useIntl } from 'react-intl';

/* @Components */
import OptimizedImage from '@/components/optimized-image';

function Feedback(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <section className="mb-24 sm:mb-40">
      <div className="mx-auto max-w-screen-lg px-4">
        <h2 className="h2 text-center">{formatMessage({ defaultMessage: 'Reviews by influencers' })}</h2>
        <ul className="flex flex-col items-center lg:flex-row lg:items-start lg:space-x-10 space-y-16 lg:space-y-0">
          <li className="max-w-md lg:w-1/3 flex-grow flex flex-col items-center">
            <figure className="mb-5 lg:mb-8 w-full">
              <div className="mb-5 lg:mb-7 rounded-md block overflow-hidden max-w-[10rem] mx-auto">
                <OptimizedImage src="/images/reviewer1@2x.jpg" alt="" width="165" height="165" />
              </div>
              <figcaption
                className="text-center opacity-40"
                dangerouslySetInnerHTML={{
                  __html: formatMessage({ defaultMessage: 'Basara {br} Famous Gadget YouTuber' }, { br: '</br>' }),
                }}
              ></figcaption>
            </figure>
            <p>
              {formatMessage({
                defaultMessage:
                  'You can easily improve speed by cleaning, update apps and OS with just button and mouse operations. It is also recommended for advanced users who can do it themselves if they research it, as it will save time. I especially like the maintenance feature.',
              })}
            </p>
          </li>
          <li className="max-w-md lg:w-1/3 flex-grow flex flex-col items-center">
            <figure className="mb-5 lg:mb-8 w-full">
              <div className="mb-5 lg:mb-7 rounded-md block overflow-hidden max-w-[10rem] mx-auto">
                <OptimizedImage src="/images/reviewer2@2x.jpg" alt="" width="165" height="165" />
              </div>
              <figcaption
                className="text-center opacity-40"
                dangerouslySetInnerHTML={{
                  __html: formatMessage(
                    { defaultMessage: 'Erina {br} Popular channels about photo editing' },
                    { br: '</br>' }
                  ),
                }}
              ></figcaption>
            </figure>
            <p>
              {formatMessage({
                defaultMessage:
                  "It's useful for seeing a list of apps you're not using and locating files you don't need. When I actually tried using it, I was surprised that I was able to secure several GB of new capacity.",
              })}
            </p>
          </li>
          <li className="max-w-md lg:w-1/3 flex-grow flex flex-col items-center">
            <figure className="mb-5 lg:mb-8 w-full">
              <div className="mb-5 lg:mb-7 rounded-md block overflow-hidden max-w-[10rem] mx-auto">
                <OptimizedImage src="/images/reviewer3@2x.jpg" alt="" width="165" height="165" />
              </div>
              <figcaption
                className="text-center opacity-40"
                dangerouslySetInnerHTML={{
                  __html: formatMessage(
                    { defaultMessage: 'Mamiyoshi {br} Legendary reviewer of Apple products' },
                    { br: '</br>' }
                  ),
                }}
              ></figcaption>
            </figure>
            <p>
              {formatMessage({
                defaultMessage:
                  'If you use a Mac for many years, cache files and garbage files will accumulate. To clean it, I have been using CleanMyMac X regularly for about a year now.',
              })}
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Feedback;
