/* @Hooks */
import { useIntl } from 'react-intl';
import OptimizedImage from '@/components/optimized-image';

function Awards() {
  const { formatMessage } = useIntl();

  return (
    <section className="mb-24 sm:mb-40">
      <div className="max-w-screen-lg mx-auto px-4 text-center">
        <h2 className="h2">{formatMessage({ defaultMessage: 'CleanMyMac X Wins Asian and International Awards' })}</h2>
        <ul className="grid xs:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-7 xs:gap-y-10">
          <li>
            <OptimizedImage src="/images/awards/asia_design@2x.png" alt="" height="130" width="352" />
            <p className="text-base px-4">{formatMessage({ defaultMessage: '2022 Asian Design Award: Gold Award' })}</p>
          </li>
          <li>
            <OptimizedImage src="/images/awards/red_dot@2x.png" alt="" height="130" width="352" />
            <p className="text-base px-4">
              {formatMessage({ defaultMessage: 'Red Dot Design Award: 2021 Brand & Communication Design Category' })}
            </p>
          </li>
          <li>
            <OptimizedImage src="/images/awards/ux_award@2x.png" alt="" height="130" width="352" />
            <p className="text-base px-4">
              {formatMessage({ defaultMessage: 'UX Design Award: 2021 Product Category' })}
            </p>
          </li>
          <li>
            <OptimizedImage src="/images/awards/IF@2x.png" alt="" height="130" width="352" />
            <p className="text-base px-4">
              {formatMessage({ defaultMessage: 'iF Design Award: 2020 Communication Design Category' })}
            </p>
          </li>
          <li>
            <OptimizedImage src="/images/awards/product_hunt@2x.png" alt="" height="130" width="352" />
            <p className="text-base px-4">
              {formatMessage({ defaultMessage: 'ProductHunt: 2018 "Best App of the Month"' })}
            </p>
          </li>
          <li>
            <OptimizedImage src="/images/awards/mac_observer@2x.png" alt="" height="130" width="352" />
            <p className="text-base px-4">
              {formatMessage({ defaultMessage: `THE MACOBSERVER: 2013 Editor's Choice Award` })}
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Awards;
