import clsx from 'clsx';

import { links } from '@/config/links';

/* @Hooks */
import { useIntl } from 'react-intl';

/* @Icons */
import Notarization from '@/icons/notarization.svg';

/* @Components */
import OptimizedImage from '@/components/optimized-image';

interface PromoBannerProps {
  theme: 'dark' | 'light';
}

function PromoBanner(props: PromoBannerProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { theme } = props;
  const storeBtnClassName = clsx('btn btn-outline w-full sm:w-max', {
    'text-white border-white border-opacity-40 hover:border-opacity-100': theme === 'dark',
    'btn-outline-gray': theme === 'light',
  });
  const bannerWrapClassName = clsx({
    'py-24 sm:py-40 flex flex-col items-center lg:flex-row lg:space-x-16 justify-center text-white bg-gradient-to-bbr from-[#251f56] via-[#1e113e] to-[#3b123c] px-8':
      theme === 'dark',
    'hidden py-24 sm:py-40 sm:flex flex-col items-center lg:flex-row lg:space-x-16 justify-center px-8':
      theme === 'light',
  });

  return (
    <div className={bannerWrapClassName}>
      <div className="mb-16 lg:mb-0 relative w-[320px] h-[242px]">
        <OptimizedImage src="/images/clean-my-mac@2x.png" alt="CleanMyMac" fill={true} />
      </div>
      <div className="max-w-[440px]">
        <h2 className="text-2xl sm:text-3xl font-bold tracking-wide mb-5 lg:mb-6">
          {formatMessage({ defaultMessage: 'Start your free trial now' })}
        </h2>
        <p className="mb-8 pr-5">
          {formatMessage({
            defaultMessage: 'Try all the features of CleanMyMac X!',
          })}
        </p>
        <div className="mb-8 flex flex-col items-center sm:flex-row sm:space-x-5 space-y-4 sm:space-y-0">
          <a
            href={links.cmmStore}
            target="_blank"
            className="btn btn-green w-full sm:w-max"
            rel="noreferrer"
            data-qa={`buy-now-promo-banner-${theme}`}
          >
            {formatMessage({ defaultMessage: 'Buy now' })}
          </a>
          <a
            className={storeBtnClassName}
            href={links.cmmDownload}
            target="_blank"
            rel="noreferrer"
            data-qa={`free-download-promo-banner-${theme}`}
          >
            {formatMessage({ defaultMessage: 'Free download' })}
          </a>
        </div>
        <figure className="space-x-1 flex items-center opacity-40 justify-center sm:pr-6">
          <Notarization />
          <figcaption className="text-base leading-tight font-medium">
            {formatMessage({ defaultMessage: 'Apple Notary' })}
          </figcaption>
        </figure>
      </div>
    </div>
  );
}

export default PromoBanner;
