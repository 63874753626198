import type { NextPage } from 'next';
import Head from 'next/head';
import Hero from '@/components/hero';
import Feedback from '@/components/feedback';
import Benefit from '@/components/benefit';
import Potential from '@/components/potential';
import Presentation from '@/components/presentation';
import Mentions from '@/components/mentions';
import PromoBanner from '@/components/promo-banner';
import Awards from '@/components/awards';

import { useIntl } from 'react-intl';

const Home: NextPage = () => {
  const { formatMessage } = useIntl();

  const seoTitle = formatMessage({ defaultMessage: 'CleanMyMac X: Mac Cleaner and Problem Solving Tool' });
  const seoDescription = formatMessage({
    defaultMessage:
      'Clean your Mac with CleanMyMac X and maintain it to run as fast as possible. This easy-to-use app has all the tools you need to ensure the smooth running of your Mac. Try it now',
  });

  return (
    <>
      <Head>
        <title>{seoTitle}</title>
        <meta property="og:title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta property="og:description" content={seoDescription} />
      </Head>
      <Hero />
      <Feedback />
      <Benefit />
      <PromoBanner theme="light" />
      <Potential />
      <Presentation />
      <Mentions />
      <Awards />
      <PromoBanner theme="dark" />
    </>
  );
};

export default Home;
