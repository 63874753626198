/* Hooks */
import { useIntl } from 'react-intl';
import OptimizedImage from '@/components/optimized-image';

function Potential(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <section className="mb-24 sm:mb-40">
      <div className="mx-auto max-w-screen-lg px-4">
        <h2 className="h2 text-center">{formatMessage({ defaultMessage: 'Unleash the potential of macOS now' })}</h2>
        <ul className="flex flex-col items-center lg:flex-row lg:items-start lg:space-x-10 space-y-16 lg:space-y-0">
          <li className="max-w-md lg:w-1/3 flex-grow flex flex-col items-center">
            <h3 className="h3 h3-bullet before:bg-macpaw-green">
              {formatMessage({ defaultMessage: 'Powerful uninstaller' })}
            </h3>
            <div className="mb-4 lg:mb-8 w-full max-w-[10rem] mx-auto">
              <OptimizedImage src="/images/uninstaller@2x.png" alt="" width="165" height="165" />
            </div>
            <p>
              {formatMessage({
                defaultMessage:
                  'Dragging the app to the Trash does not actually delete all the files. Free up space by completely deleting unwanted files of uninstalled apps on your Mac with just one click.',
              })}
            </p>
          </li>
          <li className="max-w-md lg:w-1/3 flex-grow flex flex-col items-center">
            <h3 className="h3 h3-bullet before:bg-macpaw-green">
              {formatMessage({ defaultMessage: 'Privacy protection' })}
            </h3>
            <div className="mb-4 lg:mb-8 w-full max-w-[10rem] mx-auto">
              <OptimizedImage src="/images/privacy@2x.png" alt="" width="165" height="165" />
            </div>
            <p>
              {formatMessage({
                defaultMessage:
                  'Keep your information that should be private private by deleting online and offline history such as browsing history and chat records. Even information such as old Wi-Fi network lists are organized neatly.',
              })}
            </p>
          </li>
          <li className="max-w-md lg:w-1/3 flex-grow flex flex-col items-center">
            <h3 className="h3 h3-bullet before:bg-macpaw-green">
              {formatMessage({ defaultMessage: 'Virus removal' })}
            </h3>
            <div className="mb-4 lg:mb-8 w-full max-w-[10rem] mx-auto">
              <OptimizedImage src="/images/malware-cleanup@2x.png" alt="" width="165" height="165" />
            </div>
            <p>
              {formatMessage({
                defaultMessage:
                  'Scan every folder on your Mac to detect threats to macOS such as malware, adware, and ransomware. And you can remove harmful programs with just one click.',
              })}
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Potential;
