/* @Hooks */
import { useIntl } from 'react-intl';

import { links } from '@/config/links';

/* @Icons */
import Notarization from '@/icons/notarization.svg';
import OptimizedImage from '../optimized-image';

function Hero(): JSX.Element {
  const { formatMessage } = useIntl();
  const labels = [formatMessage({ defaultMessage: 'Compatible with M2 Chip' })];

  return (
    <section className="mb-24 md:mb-32">
      <div className="mx-auto max-w-screen-lg px-4 space-y-9 md:space-x-8 md:space-y-0 flex md:items-center flex-col md:flex-row">
        <div className="md:w-1/2">
          <h1
            className="text-2xl md:text-3xl leading-normal mb-5 md:mb-11 font-bold"
            dangerouslySetInnerHTML={{
              __html: formatMessage(
                {
                  defaultMessage:
                    'Please try to imagine. {br} Your Mac starts working with {br} performance as good as new.',
                },
                { br: '</br>' }
              ),
            }}
          />
          <p className="mb-10 md:mb-14">
            {formatMessage({
              defaultMessage: `CleanMyMacX is a world standard all-in-one package developed by MacPaw to make your Mac life more comfortable. Get rid of huge amounts of unwanted files with just one click and improve your Mac's behavior. Please try it for free.`,
            })}
          </p>
          <footer className="space-y-4 md:space-y-7 flex md:inline-flex flex-col">
            <nav>
              <ul className="flex space-y-4 md:space-y-0 md:space-x-4 flex-col md:flex-row">
                <li>
                  <a
                    href={links.cmmStore}
                    className="btn btn-green w-full"
                    target="_blank"
                    rel="noreferrer"
                    data-qa="buy-now-hero"
                  >
                    {formatMessage({ defaultMessage: 'Buy now' })}
                  </a>
                </li>
                <li>
                  <a
                    href={links.cmmDownload}
                    className="btn btn-outline btn-outline-gray w-full"
                    target="_blank"
                    rel="noreferrer"
                    data-qa="free-download-hero"
                  >
                    {formatMessage({ defaultMessage: 'Free download' })}
                  </a>
                </li>
              </ul>
            </nav>
            <figure className="space-x-1 flex items-center opacity-40 justify-center">
              <Notarization />
              <figcaption className="text-base leading-tight font-medium">
                {formatMessage({ defaultMessage: 'Apple Notary' })}
              </figcaption>
            </figure>
          </footer>
        </div>
        <div className="md:w-1/2 cursor-default">
          <div className="rounded-md p-4 pb-10 md:px-8 md:pt-6 md:pb-24 bg-gradient-to-b from-[#FFF6FD] to-[#E2D7F9] flex flex-col">
            <p className="opacity-30 scale-75 origin-right xs:scale-100 text-xs font-bold space-y-2 mb-4 md:mb-8 self-end flex flex-col">
              {labels.map((label) => (
                <span key={label} className="border-2 border-current rounded inline-flex px-3 py-1 justify-center">
                  {label}
                </span>
              ))}
            </p>
            <div className="block -mr-2 xs:-mr-4">
              <OptimizedImage src="images/main@2x.png" alt="" width="687" height="422" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
